import { TraderState } from "../../../types";
import { GET_INFO_TRADER, SET_INFO_TRADER } from "./actionTypes";

export const initialState: TraderState = {
	infos: undefined,
};

export const traderReducer = (state = initialState, action: { type: string, payload?: TraderState }) => {
	switch (action.type) {
		case GET_INFO_TRADER:
			return {
				...state,
			};
        case SET_INFO_TRADER:
            return {
	    		...state,
		    	infos: action.payload?.infos,
		    };
		default:
			return state;
	}
};