import { ScanServices, CardServices, CategoryServices, AccountServices } from "../api";
import { IAuthApi } from "../types";

export class APIManager {
	public static ApiMap: Map<string, any> = new Map<string, any>();
	// TODO : Récuperer les bonnes url depuis environnement
	public static readonly baseUrl = process.env.REACT_APP_BASE_URL
		? process.env.REACT_APP_BASE_URL
		: "";

	/**
	 * Initialize all api's with the correct url
	 */
	public static init(): void {
		APIManager.ApiMap.set("scan", new ScanServices(this.baseUrl));
		APIManager.ApiMap.set("card", new CardServices(this.baseUrl));
		APIManager.ApiMap.set("category", new CategoryServices(this.baseUrl));
		APIManager.ApiMap.set("account", new AccountServices(this.baseUrl));
	}

	/**
	 * Get the qrcode service.
	 */
	public static getScanStatServices(): ScanServices {
		return APIManager.get<ScanServices>("scan");
	}

	/**
	 * Get all api's that have been initialized
	 * @param name Nom de l'api a recupérer
	 */
	private static get<T>(name: string): T {
		if (APIManager.ApiMap.has(name)) return APIManager.ApiMap.get(name) as T;
		throw new Error("L'api demandée n'est pas rensignée dans l'api manager");
	}

	/**
	 * Set token for all api's
	 * @param token provided token of api authentification
	 */
	public static setTokenAPIS(token: string): void {
		if (!token || token === "")
			throw new Error(`Le token '${token}' n'est pas valide.`);
		// TODO: verifier que les autres type d'api ne renvoie pas d'erreur
		APIManager.ApiMap.forEach((api: IAuthApi) => {

			if (typeof api.setToken === 'function') {
				api.setToken(token);
			}
			/*if (APIManager.instanceOfAuthApi(api)) {
				api.setToken('');
			}*/
		});
	}

	/**
	 * Set token for all api's
	 * @param token provided token of api authentification
	 */
	public static clearTokenAPIS(): void {

		// TODO: verifier que les autres type d'api ne renvoie pas d'erreur
		APIManager.ApiMap.forEach((api: IAuthApi) => {
			if (typeof api.setToken === 'function') {
				api.setToken('');
			}
		});
	}

	public static instanceOfAuthApi(object: any): object is IAuthApi {
		return 'token' in object || 'setToken' in object;
	}

	/**
	 * Get the card service.
	 */
	public static getCardServices(): CardServices {
		return APIManager.get<CardServices>("card");
	}

	/**
	 * Get the category service.
	 */
	public static getCategoryServices(): CategoryServices {
		return APIManager.get<CategoryServices>("category");
	}

	/**
	 * Get the account service.
	 */
	public static getAccountServices(): AccountServices {
		return APIManager.get<AccountServices>("account");
	}
}
