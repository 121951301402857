import { AApi, RESTVerb, ICategory, ICategoryLink } from "../../types";
import * as httpStatus from 'http-status';

export class CategoryServices extends AApi {
	baseUrl: string;
	token?: string;

	constructor(serverURL: string) {
		super();
		this.baseUrl =  `${serverURL}/category`;
	}

	/**
	 * Récupère l'ensemble des catégories enregistré pour l'utilisateur
	 * @param user 
	 */
	public GetAllCategoriesForTrader(idTrader: string): Promise<ICategory[]> {
		return this.jsonRequest(RESTVerb.GET, `${idTrader}`).then(async (response: Response) => {
			let result = await response.json();
			if (!result || response.status !== httpStatus.OK) {
				throw new Error("Une erreur est survenue");
			}
			//console.log(response);
			return result as ICategory[];
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	public GetCategoriesFromCard(idCard: string): Promise<ICategoryLink[]> {
		return this.jsonRequest(RESTVerb.GET, `/card/find/onecard/${idCard}`).then(async (response: Response) => {
			if (response.status === httpStatus.CONFLICT) {
				throw new Error("Une erreur est survenue");
			}
			let result = await response.json();

			//console.log(response);
			return result as ICategoryLink[];
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

}
