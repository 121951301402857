import { CardState, ICategoryLink } from '../../../types';
import * as ACTION_TYPES from './action_types';

export const initialState : CardState = {
	categorySelected: undefined,
}

export async function getInitialState(): Promise<{
	categorySelected: undefined | ICategoryLink,
}> {
	//let instanceStorage = StorageServiceManager.getStorageService();
	//let accesToken = instanceStorage.getData<string>('access_token');

	//let validToken = await APIManager.getAccountServices().ValideToken(accesToken);
	return initialState;
}

export const CardReducer = (state = initialState, action: { type: string, payload?: any }) => {
	switch (action.type) {
		case ACTION_TYPES.SET_CATEGORIE_SELECTED:
			return {
				...state,
				categorySelected: action.payload,
			}
		case ACTION_TYPES.GET_CATEGORIE_SELECTED:
			return {
				...state,
			};
		default:
			return state
	}
}