import { ITraderLight } from "../../../types";
import { GET_INFO_TRADER, SET_INFO_TRADER } from "./actionTypes";

export const GetInfoTrader = () => {
	return {
		type: GET_INFO_TRADER,
	}
};

export const SetInfoTrader = (traderLightInfos: ITraderLight) => {
	return {
		type: SET_INFO_TRADER,
		payload: {
			infos: traderLightInfos
		},
	}
};