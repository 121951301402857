import { combineReducers, createStore } from "redux";
import { devToolsEnhancer } from "redux-devtools-extension";

import { CookReducer } from "./reducers/cook";
import { CustomerReducer } from "./reducers/customer";
import { EntitiesReducer } from "./reducers/entities";
import { CartReducer } from "./reducers/cart";
import { UserReducer } from "./user";
import { CardReducer } from "./reducers/card";
import { traderReducer } from "./reducers/trader";


import { AppState } from "../types";

/* Create root reducer, containing all features of the application */
const rootReducer = combineReducers<AppState>({
  user: UserReducer,
  cook: CookReducer,
  customer: CustomerReducer,
  entities: EntitiesReducer,
  cart: CartReducer,
  card: CardReducer,
  trader: traderReducer,
});

const store = createStore(
  rootReducer,
  /* preloadedState, */ devToolsEnhancer({})
);

export default store;
