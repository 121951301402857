import { AAuthApi, RESTVerb, ITraderLight } from "../../types";
import * as httpStatus from 'http-status';

export class AccountServices extends AAuthApi {
	baseUrl: string;
	token?: string;

	constructor(serverURL: string) {
		super();
		this.baseUrl = serverURL;
	}


	public getTraderInfos(idTrader: string): Promise<ITraderLight> {
		return this.AuthRequest(RESTVerb.GET, `trader/infos/soft/status/${idTrader}`).then(async (response: Response) => {
			if (response.status !== httpStatus.FOUND) {
				throw new Error(response.status.toString());
			}
			let result = await response.json();

			return result as ITraderLight;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	
}