import { UserInfos, UserRoleAuth } from "./api";
import { ICategoryLink, IDish, IMenu, ITraderLight } from "./entities";

export * from "./api";
export * from "./services";
export * from "./entities";
export * from "./shared";

export interface Dish {
	Image?: string;
	Name: string;
	Price: number;
	Ingredients: string[];
	Description?: string;
	IDDish: string;
	IDRestaurant: string;
}

export interface Dishes {
	Dish: Dish;
	Commentaire?: string;
}

export interface Command {
	IDCommand?: string | undefined;
	Status?: boolean;
	NameRestaurant?: string;
	Description?: string;
	Dishes: Dishes[];
	Commentaire?: string;
}

export interface User {
	IDRestaurant?: string;
	Login: string;
	Name: string;
}

export interface Restaurant {
	Name: string;
	IDRestaurant?: string;
}

export interface ReducerData {
	payload?: any;
	type: string;
}

export interface CustomerState {
	commands: Command[];
	cart: { Dishes: { dish: Dish; commentaire?: string }[] };
	restaurant: string;
}

export interface CookState {
	commands: Command[];
}

export interface EntitiesState {
	restaurants?: Restaurant[];
	dishes?: Dish[];
	isLoading: boolean;
	error?: string;
}

export enum UserType {
	Trader = 0,
	Customer = 1,
	SubAccount = 2,
}

export interface UserState {
	userdata?: UserInfos;
	isAuthentified: boolean;
	role?: UserRoleAuth;
}

export interface ItemsCart {
	id: string,
	item: IDish | IMenu,
	price: number,
	quantity: number,
}

export interface CartState {
	items: ItemsCart[],
	tva?: number,
	totalPrice: number,
}


/**
 * State about trader user
 */
export interface TraderState {
	infos?: ITraderLight,
}


export interface CardState {
	categorySelected: ICategoryLink | undefined;	
}

// ApplicationStateGlobal
export interface AppState {
	user: UserState;
	cook: CookState;
	customer: CustomerState;
	entities: EntitiesState;
	cart: CartState;
	card: CardState;
	trader: TraderState;
}

/*
// Namespace des in
export namespace ApplicationState {
  // UserState
}
*/
