import { UPDATE_ITEM, UPDATE_TOTAL_PRICE, UPDATE_TVA} from "./actionTypes";
import { ReduxAction } from "./types";
import { CartState } from "../../../types";

const initialState: CartState = {
	items: [],
	tva: undefined,
	totalPrice: 0,
};

const cartReducer = (state = initialState, action: ReduxAction<any>) => {
	switch (action.type) {
		case UPDATE_ITEM:
			return {
				...state,
				items: action.payload?.item,
			};
		case UPDATE_TOTAL_PRICE:
			return {
				...state,
				totalPrice: action.payload?.totalPrice,
			};
		case UPDATE_TVA:
			return {
				...state,
				tva: action.payload?.tva,
			};
		default:
			return state;
	}
};

export default cartReducer;