import { CircularProgress } from "@material-ui/core";
import { Fragment, lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";

import DisplayRoutes from "./routes/index";

const Home = lazy(() => import('../pages/Home'));
const QrCodeRouter = lazy(() => import('../pages/QrcodeRouter'));

export default function GlobalRoutes() {

	return (
		<Fragment>
			<Suspense fallback={
				<div style={{ height: "100%", width: "100%", justifyContent: "center", alignItems: "center"}}>
					<CircularProgress color="inherit" size="lg"/>
				</div>}>
				<Switch>
					<Route path="/" component={Home} exact />
					<Route path="/router" component={QrCodeRouter} />

					<DisplayRoutes />
				</Switch>
			</Suspense>
		</Fragment>
	);
};
