import React, { useEffect } from "react";
import { AppState, ITraderLight, TraderState } from "../../types";
import { connect } from "react-redux";
import { ThemeProvider } from "@material-ui/core";
import { DefaultTheme } from "../../components/themes";
import { APIManager } from "../../services";
import { useLocation } from "react-router-dom";
import queryString from 'query-string';
import { ACTION } from "../../redux/reducers/trader";


interface Props {
	traderState: TraderState,
	SetTraderState: (infos: ITraderLight) => any,
}

const InfoTraderRoutesWrapper: React.FC<Props> = (Props) => {

    const [traderInfo, setTraderInfo] = React.useState<ITraderLight | null | undefined>(null);

	const location = useLocation();
	const idSearchParsed = queryString.parse(location.search);
	const idTrader = idSearchParsed.it as string;

	const getTraderInformation = async () => {
		try {
			let trader: ITraderLight | undefined = undefined;

			if (Props.traderState?.infos) {
				trader = Props.traderState?.infos;
			} else {
				trader = await APIManager.getAccountServices().getTraderInfos(idTrader);
				Props.SetTraderState(trader);
			}
			setTraderInfo(trader);	
		} catch(excep) {
			console.log(excep);
			setTraderInfo(undefined);
		}
	}


	useEffect(() => {
		if (traderInfo != null) {
			return ;
		}
		getTraderInformation();
	}, [traderInfo]);

	if (traderInfo !== undefined) {
		return (
            <ThemeProvider theme={(DefaultTheme(traderInfo?.AppSettings?.Color))}>
                {Props.children}
            </ThemeProvider>         
		);
	}
	console.log("InfoTraderRoutes wrapper not authenthified");

	return (
        <React.Fragment>
            {Props.children}
        </React.Fragment>
	);
}


export default connect(
	(state: AppState) => ({
		traderState: state.trader,
	}),
	{
	  SetTraderState: ACTION.SetInfoTrader,
	}
)(InfoTraderRoutesWrapper);

