import { ColorResult } from "react-color";

export enum TRADER_TYPE {
	BAR = "Bar",
	RESTAURANT = "Restaurant",
	FESTIVAL = "Festival",
	FOOD_CHAIN = "Chaine de restauration",
	BRASSERIE = "Brasserie",
	OTHER = "Autre"
}

const TVA_VALUES: number[] = [
	0,
	2.1,
	5.5,
	10,
	20,
]

export {
	TVA_VALUES
}

export enum DISH_CATEGORIE {
	ENTREE = "entrée",
	PLAT = "plat",
	DESSERT = "dessert",
	ACCOMPAGNEMENT = "accompagnement",
}

enum COORDINATES_TYPE {
	POINT = "point"
}

export enum CARD_ITEM_TYPE {
	DISH = "0",
	MENU = "1",
}

export enum ADDITIONNAL_DATA_TYPE {
	checkbox = "0",
	radio = "1",
}

export enum CATEGORY_TYPE {
	BASIC = "0",
	USER = "1",
	ADDITIONNAL = "2",
	GROUPED = "3",
}
// List des entites coté back

export interface IMenu extends BaseItem {
	_id?: string;
	// List des plats de base contenu dans le menu
	dishes: IDish[] | string[];
}

export interface ICategory {
	_id?: string;
	name: string;
	description?: string;
	category_type: CATEGORY_TYPE;
};


/**
 * Liaison entre les commerçant et les catégories
 */
 export interface ICategoryLink {
	_id?: string;
	name: string;
	trader: string;
	idCard: string;
	// sous catégorie
	subCategory?: string[] | ICategoryLink[] | undefined;
	// Description ou text de la category
	description?: string | undefined;
	// Reference vers la category basique
	ref?: string | ICategory | undefined;
};

export interface IDish extends BaseItem {
	_id?: string;
	ingredients: IEditableIngredient[];
};

export interface BaseItem {
	// Définis si l'élément de la carte est archivé ou non.
	archived: boolean;
	// Disponibilité du plat
	available: boolean;
	// Horraire des réduction
	discountSchedule: IDiscountSchedule[];
	// Stock du plat
	stock?: number;
	// Quantité du plat
	quantity?: number;
	// Identifiant de la carte sur laquelle l'item est présent
	//idCarte: string;
	// Nom du plat
	name: string;
	// Description de l'item
	description?: string;
	// Prix Hors taxe
	priceHT: number;
	// Prix tout taxes comprise
	priceTTC: number;
	// Catégorie du plats
	category: string | ICategoryLink;
	// Image principal du plat
	picturePrincipal: string;
	// Gallerie de photos du plat
	gallerie?: string[];
	// Identifiant du commercant
	trader: string;
	// Additionnal data about how to compose the item
	additionnalData?: AdditionnalData[]
	// Metadata: Add additionnal data about anything on the item
	metadata?: object;
	// Date of creation
	createdAt: Date;
	// Date of update
	updatedAt: Date;
	// Shape to be display to user
	displayShape: DISPLAY_SHAPE_ITEM;
}

export enum DISPLAY_SHAPE_ITEM {
	Square = "Square",
    Large = "Large",
}


export interface AdditionnalData {
	name: string;
	description?: string,
	type: ADDITIONNAL_DATA_TYPE,
	items: AdditionnalItem[],
}

export interface AdditionnalItem {
	name: string;
	ref?: IDish | string;
	additionnalPrice?: number;
}

export interface ICard {
	_id?: string;
	items: CardItem[],
	banner?: string,
	categoriesOrder?: ICategory[] | string[],
	starProducts: CardItem[],
	trader: string;
	name: string;
	template?: number;
	reviewLink?: string;
	announce?: string;
};


export interface CardItem {
	dishes?: IDish | string,
	menus?: IMenu | string,
	type: CARD_ITEM_TYPE,
}

/**
 * Données de facturation d'un commercant
 */
export interface IStripeBilling {
	_id?: string;
	customer_id?: string;
	subscription_id?: string;
	status: string,
	subscription_pro_plan_id?: string,
	trial_end_date?: Date,
	quantity?: Number,
	current_period_end?: Date,
	discount?: {
		percent_off: Number,
		end: Date,
	},
	cancel_at?: Date,
	cancel_at_period_end?: boolean,
	default_payment_method?: string,
	product_name: string,
	stripe_price: string,
	stripe_product: string,
	trader: string;
}

/** Utilisateur */
export interface ICustomer {
	_id?: string,
	email: string;
	password: string;
	lastname: string;
	firstname: string;
	dob: Date;
	allergens: string[];
	permanent_token?: string;
	verified: boolean;
}

export interface IEditableIngredient {
	ingredient: IIngredient,
	editable: Boolean,
}


export interface IIngredient {
	name: string;
	category: string;
}

export interface IDiscountSchedule {
	id?: string,
	start?: Date;
	end?: Date;
	startHours: Date;
	endHours: Date;
	days: Number;
	discount: number;
};

export interface IQrcode {
	_id?: string;
	name?: string;
	guid?: string;
	trader?: string;
	activate?: boolean;
	pdfCard?: IPdfCard | string;
	digitalCard?: ICard | string;
	review?: string;
};

export interface ITrader {
	_id?: string,
	email: string;
	password?: string;
	lastname: string;
	firstname: string;
	permanent_token?: string;
	// Définis si l'identité du restaurateur a été vérifié.
	verified?: boolean;
	// Définis si l'utilisateur a payé et a le droit d'utiliser le service pour son pack.
	authorized?: boolean;
	siret?: string;
	corporate_name?: string;
	location?: COORDINATES_TYPE;
	iban?: string;
	rib?: string;
	mobile?: string;
	creation_date: Date,
	last_login?: Date,
	tva?: number;
	pack: string | IPackYum;
	trader_type: TRADER_TYPE;
	establishment_description?: string;
	metadata: {
		webSite?: string;
		etablishment_phone?: string;
		contact?: string;
		social_network?: {
			facebook?: string;
			instagram?: string;
			twitter?: string;
		},
		annoucement: string,
		schedule: {
			Sunday: [{ start: Date, end: Date }], 
			Monday: [{ start: Date, end: Date }], 
			Tuesday: [{ start: Date, end: Date }],
			Wednesday: [{ start: Date, end: Date }],
			Thursday: [{ start: Date, end: Date }],
			Friday: [{ start: Date, end: Date }], 
			Saturday: [{ start: Date, end: Date }],
		}
	}
	logo_url?: string;
	AppSettings: ApplicationDataState;
	css_options?: {
		textColor?: string;
		primaryColor?: string;
		secondaryColor?: string;
	};
	optin?: boolean;
}


export interface ITraderLight {
    _id?: string,
    verified?: boolean;
    // Définis si l'utilisateur a payé et a le droit d'utiliser le service pour son pack.
    authorized?: boolean;
    siret?: string;
    corporate_name?: string;
    location?: COORDINATES_TYPE;
    tva?: number;
    pack: string | IPackYum;
    trader_type: TRADER_TYPE;
    establishment_description?: string;
    logo_url?: string;
	metadata: {
		webSite?: string;
		etablishment_phone?: string;
		contact?: string;
		social_network?: {
			facebook?: string;
			instagram?: string;
			twitter?: string;
		},
		annoucement: string,
		schedule: {
			Sunday: [{ start: Date, end: Date }], 
			Monday: [{ start: Date, end: Date }], 
			Tuesday: [{ start: Date, end: Date }],
			Wednesday: [{ start: Date, end: Date }],
			Thursday: [{ start: Date, end: Date }],
			Friday: [{ start: Date, end: Date }], 
			Saturday: [{ start: Date, end: Date }],
		}
	},
	AppSettings: ApplicationDataState;
    css_options?: {
        textColor?: string;
        primaryColor?: string;
        secondaryColor?: string;
    };
}


export interface IPackYum {
	_id?: string;
	qrCodeLimit: number;
	kitchenScreenLimit: number;
	roomScreenLimit: number;
	subAccountLimit: number;
	listOrder: boolean;
	name: string;
	price: {
		monthly: string;
		yearly: string;
	};
	product: string;
}

export interface IPdfCard {
	_id?: string;
	trader: string;
	pdfPath: string;
	reviewLink?: string;
}

export enum TypeDataInCategory {
	IMENU,
	IDISH,
}

export interface DataInCategory {
	name: string;
	price: number;
	description: string;
	picturePrincipal: string;
	id: string;
	schedule: IDiscountSchedule[];
	type: TypeDataInCategory;
}


export interface ItemCategoryData {
	category: ICategoryLink;
	item?: BaseItem[];
	subCategories?: { categoryName: ICategoryLink, items?: BaseItem[] }[] | undefined;
}

export interface GroupedCategoriesData  extends ICategory {
	datas: ItemCategoryData[];
}

export interface IPanier {
	item: IDish[];
}

export interface AppColorPersonnalization {
	/**
	 * Couleur du text
	 */
	TextColor?: ColorResult;

	/**
	 * Couleur des titres et bouton d'action
	 */
	PrincipalColor: ColorResult;

	/**
	 * Couleur des sous-titres
	 */
	SecondaryColor: ColorResult;
	/**
	 * Dégradé de couleur
	 */
	ColorGradient: {
		Stage1: ColorResult;
		Stage2: ColorResult;
	}
}

export interface ApplicationDataState {
	Color: AppColorPersonnalization;
}