import { ColorResult } from "react-color";

/**
 * Extension methods for colors
 */
export class ColorUtils {

    /**
     * Transform ColorResult to RGB string
     * @param color 
     * @returns 
     */
    public static  toStringRGB(color: ColorResult) : string {
        return `rgb(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b})`; 
    }
    
    /**
    * Transform ColorResult to RGBA string
    * @param color 
    * @returns 
    */
    public static  toStringRGBA(color: ColorResult, opacity: number) : string {
        return `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${opacity})`;
    }

    /**
    * Transform Colors to linear gradient right
    * @param color 
    * @returns 
    */
    public static  toLinearGradientRight(color1: ColorResult, color2: ColorResult) : string {
        return `linear-gradient(to right, ${color1.hex}, ${color2.hex})`;
    }

}
