import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { StylesProvider, CssBaseline, ThemeProvider } from "@material-ui/core";

import { DefaultTheme } from "./components/themes";

import { SnackbarProvider } from 'notistack';
import GlobalRoutes from "./router";


interface Props {
}

const App: React.FC<Props> = (Props) => {

	return (
		<StylesProvider injectFirst>
			<ThemeProvider theme={DefaultTheme()}>
				<CssBaseline />
				<BrowserRouter>
					<SnackbarProvider maxSnack={8}>
						<GlobalRoutes />
					</SnackbarProvider>
				</BrowserRouter>
			</ThemeProvider>
		</StylesProvider>
	);
};

export default App;
