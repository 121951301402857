import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import { AppColorPersonnalization } from "../../types";
import { ColorUtils } from "../../utils/colorUtils";
import { CustomTheme } from "../../types/shared/customtheme";

const generalOpacity : number = 0.3;

function creatMUI(colors?: AppColorPersonnalization) {
	console.log(colors);
	let theme = createMuiTheme({
		typography: {
			fontFamily: ['Poppins',"Titillium Web", "Abel", "Fredoka One", "Lato", "Open Sans", "Roboto"].join(","),
			h1: {
				fontFamily: ['Poppins', "Titillium Web", "Abel", "Lato", "Open Sans", "Roboto"].join(","),
				fontSize: "4.5rem",
				fontWeight: "bold",
			},
			h2: {
				fontFamily: ['Poppins', "Titillium Web", "Abel", "Fredoka One", "Lato", "Open Sans", "Roboto"].join(","),
				fontSize: "3.5rem",
				fontWeight: "bold",
			},
			h3: {
				fontFamily: ['Poppins', "Titillium Web", "Abel", "Fredoka One", "Lato", "Open Sans", "Roboto"].join(","),
				fontSize: "2.5rem",
				fontWeight: "bold",
			},
			h4: {
				fontFamily: ['Poppins', "Titillium Web", "Abel", "Fredoka One", "Lato", "Open Sans", "Roboto"].join(","),
				fontSize: "1.8rem",
				fontWeight: "bold",
			},
			h5: {
				fontFamily: ['Poppins',  "Titillium Web", "Abel", "Fredoka One", "Lato", "Open Sans", "Roboto"].join(","),
				fontSize: "1.1rem",
			},
			h6: {
				fontFamily: ['Poppins', "Titillium Web", "Abel", "Fredoka One", "Lato", "Open Sans", "Roboto"].join(","),
				fontSize: "0.9rem",
			},
			subtitle1: {
				fontFamily: ['Poppins', "Titillium Web", "Abel", "Fredoka One", "Lato", "Open Sans", "Roboto"].join(","),
				fontSize: "0.75rem",
			},
			subtitle2: {
				fontFamily: ['Poppins', "Titillium Web", "Abel", "Fredoka One", "Lato", "Open Sans", "Roboto"].join(","),
				fontSize: "0.55rem",
				lineHeight: 1,
			},
			body1: {
				fontSize: "0.4rem",
				fontFamily: ['Poppins', "Titillium Web", "Abel", "Fredoka One", "Lato", "Open Sans", "Roboto"].join(","),

			},
			body2: {
				fontSize: "1rem",
			},
		},
		overrides: {
			MuiCssBaseline: {
				'@global': {
					html: {
						backgroundColor: '#fff',
					},
				},
			},
		},
		props: {},
		palette: {
			primary: {
				//main: primary ? primary : "#eaac7e",
				main: colors?.PrincipalColor ? ColorUtils.toStringRGB(colors?.PrincipalColor) : "rgb(244, 113, 80)",
				light: colors?.PrincipalColor ? ColorUtils.toStringRGBA(colors?.PrincipalColor, generalOpacity) : `rgb(244, 113, 80, ${generalOpacity})`,

			},
			secondary: {
				main: colors?.SecondaryColor ? ColorUtils.toStringRGB(colors?.SecondaryColor) : "rgb(0, 51, 102)",
				light: colors?.SecondaryColor ? ColorUtils.toStringRGBA(colors?.SecondaryColor, generalOpacity) : `rgb(0, 51, 102, ${generalOpacity})`,
			},
			background: {
				default: "#fff",
			},

		},
	});

	const defaultThemeContext: CustomTheme = {
		custom: {
			gradient: colors?.ColorGradient ? ColorUtils.toLinearGradientRight(colors?.ColorGradient.Stage1, colors?.ColorGradient.Stage2) : "linear-gradient(135deg, #eaac7e 0%, #f47150 100%)",
		},
		...theme
	};

	return responsiveFontSizes(createMuiTheme(defaultThemeContext));
}


export default creatMUI;
//#eaac7e

//main: "#9970FF",
