import { UPDATE_ITEM, UPDATE_TOTAL_PRICE, UPDATE_TVA} from "./actionTypes";
import { ItemsCart } from "../../../types";

export const UpdateItem = (item: ItemsCart[]) => ({
	type: UPDATE_ITEM,
	payload: {
		item: item,
	},
});

export const UpdateTotalPrice = (totalPrice: number) => ({
	type: UPDATE_TOTAL_PRICE,
	payload: {
		totalPrice: totalPrice,
	},
});

export const UpdateTva = (tva: number) => ({
	type: UPDATE_TVA,
	payload: {
		tva: tva,
	},
});