import React, { 
	createContext, 
	useReducer } from "react";

import * as InfoTraderReducer from "../../redux/reducers/trader";
import { } from "../../redux/reducers/auth";
import { ITraderLight } from "../../types";


const store = createContext({
    traderInfo: {},
	handleGetTraderInfo: () => { },
	handleSetTraderInfo: () => { },
});


const { Provider } = store;

const TraderInfoProvider: React.FC<{}> = (Props) => {
	const [stateInfoTraderReducer, dispatchInfoTraderReducer] = useReducer(InfoTraderReducer.traderReducer, InfoTraderReducer.initialState);

	const handleGetTraderInfo = () => {
		dispatchInfoTraderReducer(InfoTraderReducer.ACTION.GetInfoTrader());
	}

	const handleSetTraderInfo = (traderInfo: ITraderLight) => {
		dispatchInfoTraderReducer(InfoTraderReducer.ACTION.SetInfoTrader(traderInfo));
	}

	return (
		<Provider value={{
			traderInfo: stateInfoTraderReducer,
			handleGetTraderInfo: () => handleGetTraderInfo,
			handleSetTraderInfo: () => handleSetTraderInfo,
		}}>
			{Props.children}
		</Provider>
	);
}


export {
	store,
	TraderInfoProvider
}

