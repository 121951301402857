import { AAuthApi, IQrcode, RESTVerb } from "../../types";
import httpStatus from "http-status";

export class ScanServices extends AAuthApi {
	baseUrl: string;
	token?: string;

	constructor(serverURL: string) {
		super();
		this.baseUrl = serverURL;
	}

	/**
	 * Récupère l'ensemble de la carte pour un utilisateur
	 * @param userid
	 */
	public QrCodeIsScanned(idQrcode: string, source: number, idCustomer?: string,): Promise<IQrcode> {
		return this.jsonRequest(
			RESTVerb.POST,
			`scan/qrcodes/${idQrcode}`,
			{
				idCustomer: idCustomer,
				source: source
			}
		).then(async (res: Response) => {
			
			if (res.status === httpStatus.FOUND) {
				let result = await res.json();
				return result as IQrcode;
			}
			throw new Error(res.status.toString());
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}
}
