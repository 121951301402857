import * as ACTION_TYPES from './action_types';

export const initialState = {
	is_authenticated: false,
	profile: null
}

export async function getInitialState(): Promise<{ is_authenticated: boolean, profile: any }> {
	//let instanceStorage = StorageServiceManager.getStorageService();
	//let accesToken = instanceStorage.getData<string>('access_token');

	//let validToken = await APIManager.getAccountServices().ValideToken(accesToken);
	return initialState;
}

export const AuthReducer = (state = initialState, action: { type: string, payload?: any }) => {
	switch (action.type) {
		case ACTION_TYPES.LOGIN_SUCCESS:
			//console.log("login succes !!!");
			return {
				...state,
				is_authenticated: true
			}
		case ACTION_TYPES.LOGIN_FAILURE:
			return {
				...state,
				is_authenticated: false
			}
		case ACTION_TYPES.ADD_PROFILE:
			return {
				...state,
				profile: action.payload
			}
		case ACTION_TYPES.REMOVE_PROFILE:
			return {
				...state,
				profile: null
			}
		default:
			return state
	}
}