import { IStorageService } from "../types";

import { storageservice, 
//	LocalStorageService 
} from "./storageservice";

export class StorageServiceManager {

	/** Define if rgpd is fullfit */
	public static rgpd: boolean;

	/** Define if rgpd is fullfit */
	private static _storageServiceInstance: IStorageService;

	private static _defaultStorageServiceInstance: IStorageService;

	public static init() {
		this.rgpd = false;
		this._storageServiceInstance = storageservice;
	}
	/**
	 * Get the localstorage service set.
	 */
	public static getStorageService(): IStorageService {
		return storageservice;
	}

}
