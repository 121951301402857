import { IApi, IAuthApi } from "../interfaces";
import { RESTVerb as HttpMethode } from "../../shared";

/**
 * Classe abstraite concernant la creation de requete REST
 */
export abstract class AApi implements IApi {
	abstract baseUrl: string;

	getBaseUrl(): string {
		return this.baseUrl;
	}

	setBaseUrl(url: string): boolean {
		if (!url) {
			return false;
		}
		this.baseUrl = url;
		return true;
	}

	protected jsonRequest(method: HttpMethode, endpoint: string, payload?: any) {
		let verbeHttp: string = '';
		switch (method) {
			case HttpMethode.GET:
				verbeHttp = 'GET';
				break;
			case HttpMethode.POST:
				verbeHttp = 'POST';
				break;
			case HttpMethode.PATCH:
				verbeHttp = 'PATCH';
				break;
			case HttpMethode.DELETE:
				verbeHttp = 'DELETE';
				break;
			case HttpMethode.PUT:
				verbeHttp = 'PUT';
				break;
			default:
				throw new Error("Verbe http not know.");
		}

		return fetch(`${this.baseUrl}/${endpoint}`, {
			method: verbeHttp,
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(payload),
		});
	}

}

/**
 * Classe abstraite concernant le requetage authentifié vers une api
 */
export abstract class AAuthApi extends AApi implements IAuthApi {
	abstract token?: string;

	getToken(): string | undefined {
		return this.token;
	}

	protected AuthRequest(method: HttpMethode, endpoint: string, payload?: any) {
		let verbeHttp: string = '';
		switch (method) {
			case HttpMethode.GET:
				verbeHttp = 'GET';
				break;
			case HttpMethode.POST:
				verbeHttp = 'POST';
				break;
			case HttpMethode.PATCH:
				verbeHttp = 'PATCH';
				break;
			case HttpMethode.DELETE:
				verbeHttp = 'DELETE';
				break;
			case HttpMethode.PUT:
				verbeHttp = 'PUT';
				break;
			default:
				throw new Error("Verbe http not know.");
		}

		//console.log("Payload JSON:", JSON.stringify(payload));

		return fetch(`${this.baseUrl}/${endpoint}`, {
			method: verbeHttp,
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Token ${this.token}`
			},
			body: JSON.stringify(payload),
		});
	}

	setToken(token: string): void {
		this.token = token;
	}
}
