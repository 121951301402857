import  { lazy, useEffect } from "react";
import { Route } from "react-router-dom";
import InfoTraderRoutesWrapper from "../wrapper/index";
import { TraderInfoProvider } from "../../contexts/trader/index";

const CustomerDetails = lazy(() => import('../../pages/CustomerDetails'));
const DisplayCard = lazy(() => import('../../pages/Templates'));
const Order = lazy(() => import('../../pages/Order'));
const ShoppingCart = lazy(() => import('../../pages/ShoppingCart'));
const ItemDetails = lazy(() => import('../../components/ItemDetails'));

export default function DisplayRoutes() {

	useEffect(() => {
	})

	return (
        <TraderInfoProvider>
            <InfoTraderRoutesWrapper>
                <Route path="/card" component={DisplayCard} />
                <Route path="/shoppingCart" component={ShoppingCart} />
                <Route path="/order" component={Order} />
                <Route path="/customerDetails" component={CustomerDetails} />
                <Route path="/itemDetails" component={ItemDetails} />
            </InfoTraderRoutesWrapper>
        </TraderInfoProvider>
	)
}