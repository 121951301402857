import { AAuthApi, GroupedCategory, ICard, 
	//ICategory, 
	ItemCategoryData, RESTVerb } from "../../types";
import httpStatus from "http-status";

export class CardServices extends AAuthApi {
	baseUrl: string;
	token?: string;

	constructor(serverURL: string) {
		super();
		this.baseUrl = serverURL;
	}

	/**
	 * Récupère l'ensemble de la carte pour un utilisateur
	 * @param userid
	 */
/* 		public GetCard(userid: string): Promise<ICard> {
		return this.AuthRequest(RESTVerb.GET, `card/${userid}`).then(async (res: Response) => {
			let result = await res.json();

			if (!result || res.status !== httpStatus.OK) {
				throw new Error("Impossible de charger le contenu");
			}
			return result as ICard;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	} */

	/**
     * Récupère la carte d'un commerçant.
     * @param idCard Identifiant de la carte à récupérer.
     */
     public GetCard(idCard: string): Promise<ICard> {
        return this.AuthRequest(RESTVerb.GET, `card/one/${idCard}`).then(async (res: Response) => {

            if (res.status !== httpStatus.OK) {
                throw new Error(res.status.toString());
            }
            let result = await res.json();

            return result as ICard;
        }).catch((err) => {
            console.log(err);
            throw err;
        });
    }

	/**
	 * Récupère la carte d'un commerçant.
	 * @param idCard Identifiant de la carte à récupérer.
	 */
	public GetCategoriesFromCard(idCard: string): Promise<GroupedCategory[]> {
		return this.AuthRequest(RESTVerb.GET, `card/categories/${idCard}`).then(async (res: Response) => {
			if (res.status === httpStatus.INTERNAL_SERVER_ERROR) {
				throw new Error(`${httpStatus.INTERNAL_SERVER_ERROR}`);
			}
			let result = await res.json();

			return result as GroupedCategory[];
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Récupère
	 * @param idCard Identifiant de la carte à récupérer.
	 */
	public GetItemOrderedByCategory(idCard: string): Promise<ItemCategoryData[]> {
		return this.jsonRequest(RESTVerb.GET, `card/ordered/card/${idCard}`).then(async (res: Response) => {
			if (res.status === httpStatus.FOUND) {
				let result = await res.json();

				return result as ItemCategoryData[];
			}
			throw new Error(`${httpStatus.INTERNAL_SERVER_ERROR}`);
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Récupère
	 * @param idCard Identifiant de la carte à récupérer.
	 */
	 public GetLightCard(idCard: string): Promise<ICard> {
		return this.jsonRequest(RESTVerb.GET, `card/one/light/${idCard}`).then(async (res: Response) => {
			if (res.status !== httpStatus.OK) {
				throw new Error(`${httpStatus.INTERNAL_SERVER_ERROR}`);
			}
			let result = await res.json();

			return result as ICard;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

}
