export enum ComposantMode {
	Create,
	Read,
	Update,
	Delete,
}

export enum RESTVerb {
	GET,
	POST,
	PATCH,
	PUT,
	DELETE,
}

export enum DishCategory {
	Starter,
	MainCourse,
	Dessert,
	Accompagnement,
}

export enum DAYS {
	All = 0x0,  // 0 *
	Sunday = 0x1,  // 1
	Monday = 0x2,  // 2
	Tuesday = 0x4,  // 4
	Wednesday = 0x8,  // 8
	Thursday = 0x10, // 16
	Friday = 0x20, // 32
	Saturday = 0x40  // 64
};

/* enum COORDINATES_TYPE {
	POINT = "point"
} */


export enum YUMSCAN_PACK {
	FREE,
	PRO,
	PRO_PLUS,
	CHAIN,
}

export enum BILLING_TYPE {
	YEARLY = "yearly",
	MONTHLY = "monthly",
}